<template>
  <div v-if="codeTableTemp.hcmUserMapByHcmUserId">
    {{ codeTableTemp.hcmUserMapByHcmUserId[value] }}
  </div>
</template>

<script>
export default {
  name: "ProReportHcmUserIdTdField",
  mounted() {},
  props: {
    value: null,
    codeTableTemp: {
      type: Object,
      required: true,
    },
  },
};
</script>
